import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { Link } from "gatsby";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** Qué es¿? */}
            <div className="container lg:px-0 py-20">
                <Title>MASTERCLASS ONLINE MATEMÀTIQUES (2024)</Title>
                <Text>
                    Un any més arriba una classe específica de matemàtiques.
                    <br />
                    <br />
                    Et costen les mates en els exàmens?
                    <br />
                    <br />
                    Tens problemes amb les gràfiques, regles de tres, càlcul ràpid i molt més...
                    <br />
                    <br />
                    No et preocupis, tenim al millor professor per psicos.
                    <br />
                    <br />
                    El dijous 28 de desembre de 18.00 a 22.00 h impartirà una MASTERCLASS de mates
                    en directe i quedarà gravada al campus fins al 13 de gener.
                    <br />
                    <br />
                    Que les mates no et suposin un problema!
                    <br />
                    <br />
                    4 hores en directe a través de ZOOM per només 39,99 euros
                    <br />
                    <br />
                    T'esperem!
                    <br />
                    <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                        <iframe
                            title={item.title}
                            width="100%"
                            height="600px"
                            src="https://www.youtube.com/embed/cGPigreTrIU"
                            frameBorder={"0"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen={true}
                        />
                    </div>
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
